import axios from '~/services/axios_auth'

export interface RFQRequestBodyPayload {
  salesText: string,
  sku: number,
  supplierName: string,
  supplierMail: string,
  quantity: number
  wishedDeliveryDate: string
}
export interface RFQRequestBody {
  indicator: string,
  customerUuid?: string|null,
  userUuid?: string|null,
  email: string,
  firstName: string,
  lastName?: string|null,
  company: string,
  phone: string,
  message?: string|null,
  payload: RFQRequestBodyPayload
}
export interface AccumulatorRequestBodyPayload {
  applicationArea: string,
  operatingFluid: string,
  applicationPressures: string,
  prevailingTemperatures: string,
  requiredGasVolume: string,
  gasPortRefillable: string,
  gasPort: string,
  oilPort: string,
  mounting: string,
  maximumDesignSpace: string
}
export interface AccumulatorRequestBody {
  indicator: string,
  customerUuid: string|null,
  userUuid: string|null,
  firstName: string,
  lastName: string,
  email: string,
  company: string,
  phone: string|null,
  message: string|null,
  payload: AccumulatorRequestBodyPayload
}

export const useMailService = () => {
  const baseurl = useRuntimeConfig().public.BASIC_API_URL  || 'https://apim-enext-stg.azure-api.net'

  const mail_config_axios = axios.create({
    baseURL: useRuntimeConfig().public.MESSAGES_API || (baseurl + '/messages/1.0.0/mails')
  })

  const postRFQRequest = async function(requestBody: RFQRequestBody) {
    return mail_config_axios.post('', requestBody)
  }

  const postAccumulatorRequest = async function(requestBody: AccumulatorRequestBody) {
    return mail_config_axios.post('', requestBody)
  }

  return {
    postRFQRequest,
    postAccumulatorRequest
  }
}
